import SideBar from "../components/SideBar";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import Spinner from "../components/Spinner";
import SendCompanyMsg from "../components/SendCompanyMsg";
import NavBar from "../components/NavBar";
import Overlay from "../components/Overlay";

function CompanyProfile() {
  const userId = useParams();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [companyData, setCompanyData] = useState({
    address: "",
    companyName: "",
    contactPerson: "",
    email: "",
    logo: "",
    phoneNumber: "",
    town: "",
    vatNumber: "",
    website: "",
    zipCode: "",
    phoneNumber: "",
  });

  const getCompanyData = async () => {
    const docRef = doc(db, "companies", userId.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setCompanyData(docSnap.data());
      setLoading(false);
      console.log("Document data:", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  useEffect(() => {
    setLoading(true);
    getCompanyData();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
    <NavBar openNav={setOpen} opened={open} />
      <div className="flex gap-10">
        <div>
          <SideBar admin={true} open={open} />
        </div>
        
        <div className=" mt-10 ">
        <Overlay open={open} />
          <div className="flex w-full">
            <div>
              <div className="avatar">
                <div className="w-32 rounded-xl">
                  <img src={companyData.logo} />
                </div>
              </div>
              <div className="flex flex-col gap-8 ">
                <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-8">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Företagsinformation
                    </h3>
                  </div>
                  <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                        Företagsnamn
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {companyData.companyName}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                        Kontaktperson
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {companyData.contactPerson}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                        E-post
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {companyData.email}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                        Hemsida
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {companyData.websiteURL}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                        Adress
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {companyData.address}
                        </dd>
                      </div>

                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                        Telefonummer
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {companyData.phoneNumber}
                        </dd>
                      </div>

                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                        Postnummer
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {companyData.zipCode}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                        Organisationsnummer
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {companyData.vatNumber}
                        </dd>
                      </div>
                      <SendCompanyMsg
                        companyEmail={companyData.email}
                        companyUserId={userId.id}
                      />
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="divider divider-horizontal"></div>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyProfile;
