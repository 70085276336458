import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSigninCheck, useUser } from "reactfire";
import Spinner from "../components/Spinner";
import checkUserStatus from "../utils/checkUserStatus";

const LoginProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { status, data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();
  const auth = useUser();

  useEffect(() => {
    if (status === "loading") {
      setLoading(true);
    } else if (signInCheckResult.signedIn) {
      if (auth.data.email === "info@jamforbegravning.se") {
        navigate("/administrator");
      } else {
        const userId = auth.data.uid;
        checkUserStatus(userId).then(({ isApprove, isAdmin }) => {
          if (isApprove) {
            navigate("/dashboard");
          } else {
            setLoading(false)
          }
        });
      }
    } else {
      setLoading(false);
    }
  }, [status]);



  if (loading) {
    return <Spinner />;
  }
  return children;
};

export default LoginProvider;
