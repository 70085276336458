// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyCZ754rxzknDmMZq918DU5b6z4O6oemBnk",
  authDomain: "jamforbegravning-6c33f.firebaseapp.com",
  projectId: "jamforbegravning-6c33f",
  storageBucket: "jamforbegravning-6c33f.appspot.com",
  messagingSenderId: "578823214637",
  appId: "1:578823214637:web:cefdffaf19924bbc6ecaa4"
};

// Initialize Firebase
initializeApp(firebaseConfig);

// Firestore instance and localEmulator
export const db = getFirestore()
//connectFirestoreEmulator(db, 'localhost', 2022);

// Storage instance and localEmulator
export const storage = getStorage();
//connectStorageEmulator(storage, "localhost", 9199);

export  const auth = getAuth();
//connectAuthEmulator(auth, "http://localhost:9099");


//export const functions = getFunctions();
//connectFunctionsEmulator(functions, "localhost", 4002);
