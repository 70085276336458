import React from "react";

const InputString = ({label, value, change, disable}) => {
  return (
    <label htmlFor="" className="flex flex-col space-y-2">
      <span className="font-bold">{label}</span>
      <input
        className="input input-sm input-bordered w-96 max-w-xs bg-primary"
        type="text"
        value={value}
        onChange={change}
        disabled={disable}
      />
    </label>
  );
};

export default InputString;
