import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import SideBar from "../components/SideBar";
import NavBar from "../components/NavBar";
import {
  setDoc,
  doc,
  collection,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useUser } from "reactfire";
import { toast } from "react-toastify";
import PageWrapper from "../layouts/PageWrapper";
import PageContent from "../layouts/PageContent";
import Overlay from "../components/Overlay";

function Mail() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [comments, setComments] = useState([]);
  const functions = getFunctions();
  const replyToSendFunction = httpsCallable(functions, "replyto");
  const params = useParams();

  const location = useLocation();
  const loadingRef = useRef(null);

  const { subject, body, companyName, email } = location.state;
  const user = useUser();

  useEffect(() => {
    onSnapshot(
      query(
        collection(
          db,
          `companies/${user.data.uid}/recieved-messages/${params.id}/sent-messages`
        ),
        orderBy("timestamp", "asc")
      ),
      (snapshot) => {
        const commentsData = [];
        snapshot.forEach((doc) => {
          commentsData.push({ ...doc.data(), id: doc.id });
        });
        setComments(commentsData);
        console.log(commentsData);
      }
    );
  }, []);

  const replyTo = async (e) => {
    e.preventDefault();
    loadingRef.current.classList.add("loading");

    let replyToData = {
      subject,
      companyName,
      email,
      body: message,
    };

    try {
      const docRef = doc(
        collection(
          db,
          `companies/${user.data.uid}/recieved-messages/${params.id}/sent-messages`
        )
      );

      let data = {
        message,
        timestamp: serverTimestamp(),
      };
      await setDoc(docRef, { ...data });

      await replyToSendFunction(replyToData);

      toast.success("Meddelande skickat");
    } catch (error) {
      toast.error(error.message);
    }

    setMessage("");
    loadingRef.current.classList.remove("loading");
  };

  return (
    <>
      <NavBar openNav={setOpen} opened={open} />
      <PageWrapper>
        <div>
          <SideBar open={open} />
        </div>
        <PageContent>
          <Overlay open={open} />
          <form onSubmit={replyTo} className="w-[80%] items-center md:w-full">
            <div className="mt-10 md:w-1/2 ">
              <h2 className="mb-10 text-xl font-semibold">
                {subject}{" "}
                <span className="badge  badge-success	 bg-green-400">
                  Inkorg
                </span>
                <p className="text-sm font-thin text-zinc-500 mt-1">
                  From info@jamforbegravning.se
                </p>
              </h2>
              <div>
                <p>{parse(`${body}`)}</p>
              </div>

              <h3 className="mt-2 font-bold box-border">Svara:</h3>
              {comments &&
                comments.map(
                  (comment, index) =>
                    comment.message && (
                      <div
                        key={comment.id}
                        className={`p-2 bg-gray-100 rounded-lg mb-2`}
                        style={{
                          marginLeft: index <= 12 ? `${index}rem` : "12rem",
                        }}
                      >
                        {comment.message}
                      </div>
                    )
                )}
              <div>
                <textarea
                  required
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  className="textarea textarea-secondary w-full mt-10  h-36"
                  placeholder="Svara"
                ></textarea>
                <button
                  ref={loadingRef}
                  className="btn btn-accent text-white normal-case mt-5"
                >
                  Skicka
                </button>
              </div>
            </div>
          </form>
        </PageContent>
      </PageWrapper>
    </>
  );
}

export default Mail;
