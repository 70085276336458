import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ContactPage from "./pages/ContactPage";
import CompanyProfile from "./pages/CompanyProfile";
import { getAuth } from "firebase/auth"; // Firebase v9+

import Dashboard from "./pages/Dashbaord";
import Admin_Dashboard from "../src/admin/Admin_DashBoard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmedRegistration from "./pages/ConfirmedRegistration";
import { AuthProvider, useFirebaseApp } from "reactfire";
import Settings from "./settings/Settings";
import Test from "../src/pages/test";
import Mail from "./messages/Mail";
import SearchCompany from "./pages/SearchCompany";
import { RouteProvider, AdminRouteProvider, LoginProvider } from "./providers";
import ResetPassword from "./pages/ResetPassword";

function App() {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const auth = getAuth(app);

  return (
    <AuthProvider sdk={auth}>
      <div id="app">

      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <LoginProvider>
                <Login />
              </LoginProvider>
            }
          />
          <Route
            path="/test"
            element={
              <RouteProvider>
                <Test />
              </RouteProvider>
            }
          />
          <Route
            path="/mail/inbox/:id"
            element={
              <RouteProvider>
                <Mail />
              </RouteProvider>
            }
          />
          <Route
            path="/settings"
            element={
              <RouteProvider>
                <Settings />
              </RouteProvider>
            }
          />

          <Route path="/register" element={<Register />} />
          <Route path="/search" element={<SearchCompany />} />
          <Route path="/reset" element={<ResetPassword />} />

          <Route
            exact
            path="/view-company/:id"
            element={
              <RouteProvider>
                <CompanyProfile />
              </RouteProvider>
            }
          />
          <Route
            path="/contact"
            element={
              <RouteProvider>
                <ContactPage />
              </RouteProvider>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RouteProvider>
                <Dashboard />
              </RouteProvider>
            }
          />
          <Route
            path="/administrator"
            element={
              <AdminRouteProvider>
                <Admin_Dashboard />
              </AdminRouteProvider>
            }
          />
          <Route
            path="/confirmation"
            element={
              <RouteProvider>
                <ConfirmedRegistration />
              </RouteProvider>
            }
          />
        </Routes>
      </Router>
      <ToastContainer />
      </div>
    </AuthProvider>
  );
}

export default App;
