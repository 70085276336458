import React from "react";
import algoliasearch from "algoliasearch/lite";
import NavBar from "../components/NavBar";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  useConnector,
} from "react-instantsearch-hooks-web";
import connectGeoSearch from "instantsearch.js/es/connectors/geo-search/connectGeoSearch";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { MapContainer, TileLayer, useMap, Popup, Marker } from "react-leaflet";

function SearchCompany() {
  const searchClient = algoliasearch(
    "BM6O2EWSUL",
    "eb1ef93097b98717b728416050c3b17c"
  );

  function Hit({ hit }) {
    return (
      <article>
        <Highlight attribute="post_title" hit={hit} />
      </article>
    );
  }

  return (
    <>
      <NavBar />
    <div className="container flex justify-between mx-auto flex-nowrap">
      <div className="text-center mt-20">
        <InstantSearch searchClient={searchClient} indexName="wp_posts_company">
          <SearchBox placeholder="Search" className="searchbox" />
          <Hits hitComponent={Hit} />
        </InstantSearch>
      </div>

      <div className="grow-0" id="map">
       
      </div>

      </div>
    </>
  );
}

export default SearchCompany;

export function useGeoSearch(props) {
  return useConnector(connectGeoSearch, props);
}

export function GeoSearch(props) {
  const {
    items,
    position,
    currentRefinement,
    refine,
    sendEvent,
    clearMapRefinement,
    isRefinedWithMap,
    toggleRefineOnMapMove,
    isRefineOnMapMove,
    setMapMoveSinceLastRefine,
    hasMapMoveSinceLastRefine,
  } = useGeoSearch(props);

  return (
    <>
      <div>MAP</div>
    </>
  );
}
