import React from 'react'
import spinner from "../assets/spinner.gif"

function Spinner() {
  return (
  <div className=' w-100 mt-10'>
    <img 
    width={300} 
    src={spinner} alt="loading...." 
    className=' text-center mx-auto'
    
    />
  </div>
  )
}

export default Spinner