import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import Spinner from "../components/Spinner";

const RouteProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { status, data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();

  useEffect(() => {
    // navigate to login page if status is no longer loading and signInCheckResult.signedIn is false
    if (status === "loading") {
      setLoading(true);
    } else if (!signInCheckResult.signedIn) {
      navigate("/");
    } else {
      setLoading(false);
    }
  }, [status]);

  // keeps loading spinner if loading is true
  if (loading) {
    return <Spinner />;
  }
  return children;
};

export default RouteProvider;
