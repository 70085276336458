import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Spinner from "../components/Spinner";
import { updateDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase.config";
import { uploadLogo } from "../utils/uploadLogo";
import InputString from "../components/InputString";
import InputWrapper from "../components/InputWrapper";
import TownSelect from "../components/TownSelect";
import { toast } from "react-toastify";
import PageWrapper from "../layouts/PageWrapper";
import PageContent from "../layouts/PageContent";
import Overlay from "../components/Overlay";

function Settings() {
  const userId = localStorage.getItem("x-auth-uid");
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // get user data and listen for changes
    const docRef = doc(db, "companies", userId);
    onSnapshot(docRef, (snapshot) => {
      const data = snapshot.data();
      setUserData({ ...data });
      setLoading(false);
    });
  }, []);

  // update user data if user changes anything in the page
  const handleChange = (property, value) => {
    setUserData({ ...userData, [property]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, "companies", userId);
    try {
      await updateDoc(docRef, userData);
      toast.success("Profilen har uppdaterats");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <NavBar openNav={setOpen} opened={open} />
      <PageWrapper>
        <SideBar current={2} open={open} />
        <PageContent>
          <Overlay open={open} />
          <h1 className="text-3xl font-bold md:text-4xl">profil</h1>
          {loading ? (
            <Spinner />
          ) : (
            <form
              onSubmit={handleSubmit}
              className="w-fit flex flex-col items-start space-y-4"
            >
              <img
                src={userData.logo}
                alt=""
                className="w-[150px] h-[150px] rounded-full object-cover"
              />
              <label
                htmlFor="logo"
                className="flex items-start space-x-0 space-y-4 flex-col md:space-y-0 md:flex-row md:space-x-2"
              >
                <span className="font-bold">Uppdatera logotyp:</span>
                <input
                  id="logo"
                  name="logo"
                  type="file"
                  accept=".jpg,.png,.jpeg"
                  onChange={(e) => {
                    console.log("Uploading...........");
                    uploadLogo({
                      logo: e.target.files,
                      setFormData: setUserData,
                    });
                  }}
                  className="file-input file-input-bordered file-input-sm w-full max-w-xs"
                />
              </label>

              <InputWrapper>
                <InputString
                  label="Företagsnamn"
                  value={userData.companyName}
                  change={(e) => handleChange("companyName", e.target.value)}
                />
                <InputString
                  label="Organisationsnummer"
                  value={userData.vatNumber}
                  change={(e) => {
                    handleChange("vatNumber", e.target.value);
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <InputString
                  label="Telefonummer"
                  value={userData.phoneNumber}
                  change={(e) => {
                    handleChange("phoneNumber", e.target.value);
                  }}
                />
                <InputString
                  label="Kontaktperson"
                  value={userData.contactPerson}
                  change={(e) => handleChange("contactPerson", e.target.value)}
                />
              </InputWrapper>

              <InputWrapper>
                <InputString
                  label="Adress"
                  value={userData.address}
                  change={(e) => handleChange("address", e.target.value)}
                />
                <InputString
                  label="Postnummer"
                  value={userData.zipCode}
                  change={(e) => handleChange("zipCode", e.target.value)}
                />
              </InputWrapper>

              <InputWrapper>
                <TownSelect
                  value={userData.town}
                  change={(e) => handleChange("town", e.target.value)}
                />
                <InputString
                  label="Hemsida"
                  value={userData.websiteUrl}
                  change={(e) => handleChange("websiteUrl", e.target.value)}
                />
              </InputWrapper>

              <InputString
                label="E-post"
                value={userData.email}
                disable={true}
              />

              <div className="py-2 w-full md:flex md:justify-start">
                <button className="btn btn-xs btn-md bg-accent text-white btn-wide mb-5 w-full md:w-[48.5%]">
                  Spara
                </button>
              </div>
            </form>
          )}
        </PageContent>
      </PageWrapper>
    </>
  );
}

export default Settings;
