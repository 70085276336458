import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getFunctions, httpsCallable } from "firebase/functions";
import { setDoc, doc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase.config";

function SendCompanyMsg({ companyEmail, companyUserId }) {
  const functions = getFunctions();
  const sendCompanyMessage = httpsCallable(functions, "sendCompanyMessage");

  const [formData, setFormData] = useState({ subject: "" });
  const [msg, setMsg] = useState("");

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const sendMessageHandler = async (e) => {
    e.preventDefault();

    let inBoxMsgData = {
      subject: formData.subject,
      body: msg,
      read: false,
      timestamp: serverTimestamp(),
    };

    let sendCompanyMsgData = {
      toEmail: companyEmail,
    };

    //Calling callable cloud function
    sendCompanyMessage(sendCompanyMsgData);

    // Send message to company inbox
    const docRef = doc(
      collection(db, `companies/${companyUserId}/received-messages`)
    );
    await setDoc(docRef, inBoxMsgData);

    toast.success("Meddelande skickat");

    //Reset FormData
    setFormData({
      subject: "",
    });
    //Reset Msg
    setMsg("");
  };

  useEffect(() => {}, [formData, msg]);

  return (
    <>
      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        {/* The button to open modal */}
        <label
          htmlFor="my-modal-3"
          className="btn btn-wide bg-accent text-white normal-case "
        >
          Skicka meddelande
        </label>
      </div>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my-modal-3" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Nytt meddelande</h3>

          <form onSubmit={sendMessageHandler}>
            <div className="flex gap-5 mt-5">
              <div className=" w-16">Till</div>
              <input
                value={companyEmail}
                required
                type="email"
                readOnly
                placeholder="hello@example.com"
                className="input input-bordered input-sm grow"
              />
            </div>

            <div className="flex gap-5 mt-5">
              <div className=" w-16">Ämne</div>
              <input
                id="subject"
                value={formData.subject}
                onChange={onChange}
                name="subject"
                required
                type="text"
                placeholder="Hej där!"
                className="input input-bordered input-sm grow"
              />
            </div>

            <div className="flex gap-5 mt-5">
              <div className=" w-16">Meddelande</div>

              <div className=" flex-wrap w-96">
                {" "}
                <CKEditor
                  config={{
                    placeholder: "",
                    outerHeight: 160,
                  }}
                  editor={ClassicEditor}
                  data={msg}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setMsg(data);

                    console.log({ event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                    editor.outerHeight = 160;
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>

            <div className="flex gap-5 mt-5 justify-end">
              <div className=" w-16"></div>
              <button
                type="submit"
                className="btn btn-accent text-white normal-case"
              >
                Skicka
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SendCompanyMsg;
