import React from "react";
import { useEffect, useState } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  getDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase.config";

import SideBar from "../components/SideBar";
import NavBar from "../components/NavBar";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import PageContent from "../layouts/PageContent";
import PageWrapper from "../layouts/PageWrapper";
import Overlay from "../components/Overlay";

function Dashboard() {
  const [open, setOpen] = useState(false)
  const [receivedMsg, setReceivedMsg] = useState([]);
  const [userData, setUserData] = useState({});
  const userId = localStorage.getItem("x-auth-uid");
  const [loading, setLoading] = useState(false);

  const getCompanyData = async () => {
    const docRef = doc(db, "companies", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setLoading(false);
      setUserData(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  useEffect(() => {
    const getMsg = async () => {
      const receivedMessageData = [];
      onSnapshot(
        query(
          collection(db, `companies/${userId}/received-messages`),
          orderBy("timestamp", "desc")
        ),
        (snapshot) => {
          snapshot.forEach((doc) => {
            receivedMessageData.push({ ...doc.data(), id: doc.id });
          });
          setReceivedMsg(receivedMessageData);
        }
      );
    };

    getMsg();
    getCompanyData();
    setLoading(true);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  const readMessage = async (messageId) => {
    const docRef = doc(
      collection(db, `companies/${userId}/received-messages`),
      messageId
    );

    try {
      await updateDoc(docRef, { read: true });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <NavBar openNav={setOpen} opened={open} />
      <PageWrapper>
        <SideBar current={1} open={open} />

        <PageContent>
        <Overlay open={open} />
          {receivedMsg.length > 0 ? receivedMsg.map((message, index) => (
            <div
              key={index}
              className="card card-compact w-[80%] md:w-96 bg-primary shadow-md"
            >
              <div className="card-body">
                <h2 className="card-title text-lg">
                  <Link
                    onClick={() => {
                      readMessage(message.id);
                    }}
                    to={`/mail/inbox/${message.id}`}
                    state={{
                      subject: message.subject,
                      body: message.body,
                      companyName: userData.companyName,
                      email: userData.email,
                    }}
                  >
                    {message.subject}
                  </Link>

                  {message.read === true ? (
                    <span className="badge badge-md  bg-slate-900 text-slate-50">
                      Läst
                    </span>
                  ) : (
                    <span className="badge badge-md bg-green-400 ">Nytt</span>
                  )}
                </h2>
                <p className=" w-auto truncate">{parse(`${message.body}`)}</p>
              </div>
            </div>
          )):
          <div>Inga meddelanden än</div>}
        </PageContent>
      </PageWrapper>
    </>
  );
}

export default Dashboard;
