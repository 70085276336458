import React from "react";

const Overlay = ({open}) => {
  return (
    <div
      className={` ${
        open ? "block" : "hidden"
      } w-full h-[150%] bg-gray-700 z-[12] absolute bg-opacity-60 md:hidden`}
    ></div>
  );
};

export default Overlay;
