import React from "react";

const PageWrapper = ({ children }) => {
  return (
    <div className="flex gap-10 container box-border justify-center w-[100%]">
      {children}
    </div>
  );
};

export default PageWrapper;
