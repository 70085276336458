import React from 'react'

const InputWrapper = ({children}) => {
  return (
    <div className="flex flex-col space-x-0 space-y-4 md:flex-row md:items-end md:space-x-4 md:space-y-0">
        {children}
    </div>
  )
}

export default InputWrapper