import { storage } from "../firebase.config";
import {
  //getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

export const uploadLogo = async ({ logo, id}) => {
  //Store Logo in firebase
  //const storage = getStorage();
  const logoName = logo[0].name
  // get extension
  const extension = logoName.substring(logoName.lastIndexOf('.') + 1)
  console.log(extension)
  const fileName = `${id}-pic.${extension}`;
  const storageRef = ref(storage, "logos/" + fileName);
  await uploadBytes(storageRef, logo[0])
  
  const downloadUrl = await getDownloadURL(storageRef);


  return downloadUrl
};
