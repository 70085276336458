import React from "react";
import data from "../data/se.json"

const TownSelect = ({ value, change, lg }) => {
  return (
    <select
      className={`select select-bordered ${lg ? "select-lg" : "select-md"} w-96 max-w-xs  bg-primary`}
      required
      id="town"
      value={value}
      onChange={change}
    >
      {data.sort((a, b) => a.city > b.city ? 1 : -1).map(state => (
        <option value={state.city}>{state.city}</option>
      ))}
    </select>
  );
};

export default TownSelect;
