import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function ConfirmedRegistration() {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-center my-10">
        <div className="card w-96 bg-base-100 shadow-xl">
          <div className="px-10 pt-10 flex justify-center">
            <BsFillCheckCircleFill size={96} color="green" />
          </div>
          <div className="card-body items-center text-center">
            <h2 className="card-title text-2xl text-green-700 ">
              Registrering lyckad!
            </h2>
            <p className=" mt-5">
              Tack för att du registrerade ditt konto. Vi granskar din ansökan
              innan den <span className="font-bold">godkänns</span>. Vid frågor
              kontakta oss på{" "}
              <span className=" font-bold">info@jamforbegravning.se.</span>
            </p>
            <div className="card-actions">
              <button
                onClick={() => navigate("/")}
                className="btn btn-primary normal-case rounded-full mt-2"
              >
                Besök hemsida
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmedRegistration;
