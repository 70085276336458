import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase.config";
import { toast } from "react-toastify";
import checkUserStatus from "../utils/checkUserStatus";

function Login() {
  const navigate = useNavigate();
  const loadingRef = useRef(null);

  //const  [ formData, setFormData ] = useState({ email: "", password_: "" });

  const submitHandler = (e) => {
    e.preventDefault();
    loadingRef.current.classList.add("loading");

    //Initiate login.....'
    const form = new FormData(e.target);
    const formData = Object.fromEntries(form.entries());

    const email = formData.email.trim();
    const password = formData.password.trim();

    //const auth = getAuth();

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        toast.success("inloggningen lyckades!");
        // store user id in local storage
        localStorage.setItem("x-auth-uid", `${auth.currentUser.uid}`);
        loadingRef.current.classList.remove("loading");
        //Check if user is Admin
        if (userCredential.user.email === "info@jamforbegravning.se") {
          navigate("/administrator");
          return null;
        }
        //Check if user is Approve
        const { isApprove, isAdmin } = await checkUserStatus(
          userCredential.user.uid
        );

        if (isApprove) {
          console.log("Approved");
          //Navigate to UserDashBoard
          navigate("/dashboard");
        } else {
          console.log("Not approved");
          toast.error("Account Not Yet Approved!");
          //Not Approve
        }
      })
      .catch((error) => {
        loadingRef.current.classList.remove("loading");
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        toast.error(errorMessage);
      });
  };

  return (
    <form onSubmit={submitHandler} className="overflow-hidden">
      <div className="min-h-screen bg-secondary relative overflow-hidden box-border flex justify-center items-center">
        <div className="absolute w-60 h-60 rounded-xl bg-primary -top-5 -left-16 transform rotate-45 hidden md:block"></div>
        <div className="absolute w-48 h-48 rounded-xl bg-primary bottom-0 -right-10 transform rotate-12 hidden md:block"></div>
        <div className="py-12 px-12 bg-white rounded-2xl shadow-xl z-20">
          <div>
            <h1 className="text-3xl font-bold text-center mb-4 cursor-pointer">
              Välkommen! Logga in
            </h1>
            <p className="w-80 text-center text-sm mb-8 font-semibold text-gray-700 tracking-wide cursor-pointer">
              logga in med användarnamn och lösenord.
            </p>
          </div>
          <div className="space-y-4">
            <input
              type="email"
              name="email"
              required
              placeholder="e-post"
              className="block text-sm py-3 px-4 rounded-lg w-full border outline-none"
            />
            <input
              type="password"
              name="password"
              required
              placeholder="Lösenord"
              className="block text-sm py-3 px-4 rounded-lg w-full border outline-none"
            />
          </div>
          <div
            
            className="flex w-full justify-end py-2 text-sm text-accent"
          >
            <p onClick={() => navigate("/reset")} className="underline cursor-pointer">Glömt ditt lösenord?</p>
          </div>
          <div className="text-center mt-4">
            <button
              ref={loadingRef}
              type="submit"
              className=" btn-wide text-white bg-accent rounded-2xl py-3 btn  md:btn-md lg:btn-md "
            >
              Logga in
            </button>

            <p className="mt-4 text-sm">
              Anslut företag?{" "}
              <span
                className="underline cursor-pointer text-accent"
                onClick={() => {
                  navigate("/register");
                }}
              >
                {" "}
                Registrera{" "}
              </span>
            </p>
          </div>
        </div>
        <div className="w-40 h-40 absolute bg-primary rounded-full top-0 right-12 hidden md:block"></div>
        <div className="w-20 h-40 absolute bg-primary rounded-full bottom-20 left-10 transform rotate-45 hidden md:block"></div>
      </div>
    </form>
  );
}

export default Login;
