import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config";

const checkUserStatus = async (userId) => {
  const docRef = doc(db, "companies", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const { isApprove } = docSnap.data();
    const data = docSnap.data();
    return { isApprove, isAdmin: data.isAdmin };
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
};

export default checkUserStatus;
