import React, { useRef } from "react";
// import {httpsCallable, getFunctions} from "firebase/functions"
import {sendPasswordResetEmail} from "firebase/auth"
import {auth} from "../firebase.config"
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const loadingRef = useRef(null);
  const navigate = useNavigate()
  
  // const functions = getFunctions()
  // const sendResetEmailLink = httpsCallable(functions, "sendResetEmailLink");

  const handleSubmit = (e) => {
    e.preventDefault();
    loadingRef.current.classList.add("loading");

    const form = new FormData(e.target);
    const formData = Object.fromEntries(form.entries());
    const email = formData.email.trim();

    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("återställ e-post skickat");
        loadingRef.current.classList.remove("loading");
      })
      .catch((error) => {
        loadingRef.current.classList.remove("loading");
        toast.error(error.message);
      });

      // sendResetEmailLink({email})
      // .then(() => {
      //   toast.success("återställ e-post skickat");
      //   loadingRef.current.classList.remove("loading");
      // })
      // .catch((error) => {
      //   loadingRef.current.classList.remove("loading");
      //   toast.error(error.message);
      // });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="min-h-screen bg-secondary relative overflow-hidden box-border flex justify-center items-center">
        <div className="absolute w-60 h-60 rounded-xl bg-primary -top-5 -left-16 transform rotate-45 hidden md:block"></div>
        <div className="absolute w-48 h-48 rounded-xl bg-primary bottom-0 -right-10 transform rotate-12 hidden md:block"></div>
        <div className="py-12 px-12 bg-white rounded-2xl shadow-xl z-20">
          <h1 className="text-3xl font-bold text-center capitalize mb-4 cursor-pointer">
            Återställ lösenord
          </h1>
          <p className="w-80 text-center text-sm mb-8 font-semibold text-gray-700 tracking-wide cursor-pointer">
          ange e-post för att få återställningslänk
          </p>

          <input
            type="email"
            name="email"
            required
            placeholder="e-post"
            className="block text-sm py-3 px-4 rounded-lg w-full border outline-none"
          />

          <div className="text-center mt-4">
            <button
              ref={loadingRef}
              type="submit"
              className=" btn-wide text-white bg-accent rounded-2xl py-3 btn btn-xs btn-md "
            >
              Få mejl
            </button>

            <p className="mt-4 text-sm">
            ändrade dig?{" "}
              <span
                className="underline cursor-pointer text-accent"
                onClick={() => {
                  navigate("/");
                }}
              >
                {" "}
                logga in{" "}
              </span>
            </p>
          </div>
        </div>

        <div className="w-40 h-40 absolute bg-primary rounded-full top-0 right-12 hidden md:block"></div>
        <div className="w-20 h-40 absolute bg-primary rounded-full bottom-20 left-10 transform rotate-45 hidden md:block"></div>
      </div>
    </form>
  );
};

export default ResetPassword;
