import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSigninCheck, useUser } from "reactfire";
import Spinner from "../components/Spinner";

const AdminRouteProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { status, data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();
  const auth = useUser()

  useEffect(() => {
    if (status === "loading") {
      setLoading(true);
    } else if (signInCheckResult.signedIn) {
      if (auth.data.email === "info@jamforbegravning.se") {
        setLoading(false)
      } else {
        navigate('/dashboard')
      }
    } else {
      navigate('/');
    }
  }, [status]);


  if (loading) {
    return <Spinner />
  }
  return children;
};

export default AdminRouteProvider;
