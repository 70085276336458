import React from "react";
import SideBar from "../components/SideBar";
import { MdDeleteForever } from "react-icons/md";
import {
  getDocs,
  collection,
  setDoc,
  doc,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { useState, useEffect, useRef } from "react";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import NavBar from "../components/NavBar";
import searchIcon from "../assets/search.png";
import Overlay from "../components/Overlay";

function Admin_Dashboard() {
  const [userData, setUserData] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);

  const functions = getFunctions();
  const sendMsgOnAccActivatedOrDeclined = httpsCallable(
    functions,
    "sendMsgOnAccActivatedOrDeclined"
  );

  const handleDelete = httpsCallable(functions, "handleDelete");

  const getUsersData = async () => {
    const fetchedUserData = [];

    onSnapshot(
      query(collection(db, "companies"), orderBy("timestamp", "desc")),
      (snapshot) => {
        snapshot.forEach((doc) => {
          fetchedUserData.push({ ...doc.data(), id: doc.id });
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data().receivedMessage);
        });

        setUserData(fetchedUserData);
        setfilteredData(fetchedUserData);
        setLoading(false);
      }
    );

    //const querySnapshot = await getDocs(collection(db, "companies"));
  };

  useEffect(() => {
    setLoading(true);
    getUsersData();
  }, [reload]);

  if (loading) {
    return <Spinner />;
  }

  const filterData = (companyName) => {
    const computedData =
      userData &&
      userData.filter((user) =>
        user.companyName.toLowerCase().includes(companyName)
      );
    setfilteredData(computedData);
  };

  return (
    <>
      <NavBar openNav={setOpen} opened={open} />
      <div className="flex md:space-x-4 box-border">
        <SideBar admin={true} current={0} open={open} />

        <div className="mt-4 w-full">
          <Overlay open={open} />
          <div className="overflow-x-auto box-border">
            <div className="flex p-4 justify-end sticky left-0">
              <div className="flex space-x-2 items-center">
                <img src={searchIcon} alt="search" className="w-8" />
                <input
                  type="text"
                  name="search"
                  placeholder="sökföretag"
                  className="input input-sm input-bordered"
                  onChange={(e) => filterData(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full flex ">
              <table className="table table-auto">
                <thead>
                  <tr>
                    <th></th>
                    <th>Företagsnamn</th>
                    <th>Kontaktperson</th>

                    <th>Epost</th>

                    <th>Hemsida</th>
                    <th>Status</th>
                    <th>Organisationsnummer</th>
                    <th>Alternativ</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((user, index) => (
                      <tr key={user.id}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="flex items-center space-x-3">
                            <div className="avatar">
                              <div className="w-12 rounded-full">
                                <Link to={`/view-company/${user.id}`}>
                                  {" "}
                                  <img src={user.logo} alt="logo" />
                                </Link>
                              </div>
                            </div>
                            <div>
                              <div className="font-bold">
                                <Link to={`/view-company/${user.id}`}>
                                  {user.companyName}
                                </Link>
                              </div>
                              <div className=" bg-primary badge badge-ghost badge-sm">
                                <span>{user.town}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span>{user.contactPerson}</span>
                          <br />
                          <span className="badge badge-ghost badge-sm">
                            {user.phoneNumber}
                          </span>
                        </td>
                        <td>{user.email}</td>
                        <td>{user.websiteURL}</td>

                        <td>
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span
                              aria-hidden
                              className={`absolute inset-0  ${
                                user.isApprove ? "bg-green-300" : "bg-red-300"
                              } opacity-50 rounded-full`}
                            />
                            <span className="relative text-xs">
                              {user.isApprove === true ? "actived" : "inactive"}
                            </span>
                          </span>
                        </td>

                        <td>{user.vatNumber}</td>

                        <td>
                          <div className="flex flex-row items-center space-x-2">
                            <button
                              onClick={async (e) => {
                                e.preventDefault();
                                const approveRef = doc(
                                  db,
                                  "companies",
                                  user.id
                                );
                                setDoc(
                                  approveRef,
                                  { isApprove: true },
                                  { merge: true }
                                );
                                setReload(!reload);
                                sendMsgOnAccActivatedOrDeclined({
                                  companyName: user.companyName,
                                  isApprove: true,
                                  email: user.email,
                                });
                                toast.success("Approved");
                              }}
                              className="btn bg-green-300 rounded-full btn-sm normal-case"
                            >
                              Godkänna
                            </button>
                            <button
                              onClick={async (e) => {
                                e.preventDefault();
                                const approveRef = doc(
                                  db,
                                  "companies",
                                  user.id
                                );
                                setDoc(
                                  approveRef,
                                  { isApprove: false },
                                  { merge: true }
                                );
                                setReload(!reload);
                                sendMsgOnAccActivatedOrDeclined({
                                  companyName: user.companyName,
                                  isApprove: false,
                                  email: user.email,
                                });
                                toast.success("Done");
                              }}
                              className="btn bg-red-300 rounded-full btn-sm normal-case"
                            >
                              Neka
                            </button>

                            <button
                              onClick={() => {
                                handleDelete({ id: user.id })
                                  .then(() => {
                                    toast.success("User deleted");
                                    setTimeout(() => setReload(true), 3000);
                                  })
                                  .catch((err) => toast.error(err.message));
                              }}
                              className="btn rounded-full btn-sm bg-red-600 "
                            >
                              <MdDeleteForever color="white" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="w-full font-bold">Company Not found</tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div></div>
        </div>
      </div>
    </>
  );
}

export default Admin_Dashboard;
