import React from "react";
import { useNavigate } from "react-router-dom";
import ic_menu from "../assets/menu.png";
import ic_close from "../assets/close.png";


function NavBar({ openNav, opened }) {
  const navigate = useNavigate();

  return (
      <div className="navbar bg-neutral">
        <div className="navbar-start">
          <div className="sm:hidden flex justify-end items-center">
            {/* Menu Button */}
            <img
              src={opened ? ic_close : ic_menu}
              alt=""
              className="w-[28px] h-[28px] object-contain"
              onClick={() => openNav((prev) => !prev)}
            />
          </div>
        </div>
        <div className="navbar-center">
          <div
            onClick={() => navigate("/")}
            className="btn btn-ghost normal-case text-xl"
          >
            {" "}
            Jämförbegravning.se
          </div>
        </div>
        <div className="navbar-end"></div>
      </div>
  );
}

export default NavBar;
