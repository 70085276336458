import React from "react";
import { MdOutlineForwardToInbox } from "react-icons/md";
import { FaSignOutAlt, FaHome } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function SideBar({ admin = false, current, open = false }) {
  const navigate = useNavigate();
  const auth = getAuth();

  const logOut = async () => {
    await auth.signOut().then(() => localStorage.setItem("x-auth-uid", ""));
    toast.success("logga ut lyckades!");
    navigate("/");
  };

  return (
    <>
      <div
        className={`bg-white ${
          open ? "block" : "hidden"
        } md:block sidebar z-[20] fixed md:relative left-0`}
      >
        <div className="w-48 border-r z md:sticky top-0 flex h-screen flex-col justify-between pt-2">
            <ul className="mt-6 flex w-full flex-col space-y-2 tracking-wide">
              {admin && (
                <li className="w-full">
                  <div
                    onClick={() => navigate("/administrator")}
                    className={`group flex items-center space-x-4 rounded-md px-4 py-3 cursor-pointer text-gray-600 ${
                      current === 0 ? "font-bold" : "font-normal"
                    }`}
                  >
                    <FaHome size={20} />
                    <span className="group-hover:font-bold">Skrivbord</span>
                  </div>
                </li>
              )}
              {!admin && (
                <>
                  <li className="w-full">
                    <div
                      onClick={() => navigate("/dashboard")}
                      className={`group w-full flex items-center space-x-4 rounded-md px-4 py-3 cursor-pointer text-gray-600 ${
                        current === 1 ? "font-bold" : "font-normal"
                      }`}
                    >
                      <MdOutlineForwardToInbox size={20} />
                      <span className="group-hover:font-bold">Inkorg</span>
                    </div>
                  </li>

                  <li className="">
                    <div
                      onClick={() => navigate("/settings")}
                      className={`group flex items-center space-x-4 cursor-pointer rounded-md px-4 py-3 text-gray-600 ${
                        current === 2 ? "font-bold" : "font-normal"
                      }`}
                    >
                      <IoMdSettings size={20} />
                      <span className="group-hover:font-bold">
                        inställningar
                      </span>
                    </div>
                  </li>
                </>
              )}

              <li className="">
                <div
                  onClick={logOut}
                  className="group flex items-center space-x-4 cursor-pointer rounded-md px-4 py-3 text-gray-600"
                >
                  <FaSignOutAlt size={20} />
                  <span className="group-hover:font-bold">Logga ut</span>
                </div>
              </li>
            </ul>
        </div>
      </div>
    </>
  );
}

export default SideBar;
