import React, { useRef, useState } from "react";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { db, auth } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { uploadLogo } from "../utils/uploadLogo";
import { createUserWithEmailAndPassword } from "firebase/auth";

import TownSelect from "../components/TownSelect";

function Register() {
  const functions = getFunctions();
  const sendMessageOnRegistered = httpsCallable(
    functions,
    "sendMessageOnRegistered"
  );

  const [image, setImage] = useState();
  const [formData, setFormData] = useState({
    companyName: "",
    vatNumber: "",
    phoneNumber: "",
    contactPerson: "",
    address: "",
    logo: "",
    zipCode: "",
    town: "town",
    websiteURL: "",
    email: "",
    password: "",
    isApprove: false,
    isAdmin: false,
  });

  const loadingRef = useRef(null);
  const navigate = useNavigate();

  //const auth = getAuth();

  const {
    companyName,
    vatNumber,
    phoneNumber,
    contactPerson,
    address,
    zipCode,
    town,
    websiteURL,
    email,
    password,
  } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    loadingRef.current.classList.add("loading");
    //Initiate registration
    const email = formData.email.trim();
    const passWord = formData.password.trim();

    //Copy of formData to send to Firestore
    let fireStoreData = {
      ...formData,
      timestamp: serverTimestamp(),
    };

    delete fireStoreData.password;

    createUserWithEmailAndPassword(auth, email, passWord)
      .then(async (userCredential) => {

        let companyData =  {
          email,
          companyName
        }
        console.log("Hej You have succesfully registered");
        toast.success("Registering lyckad");
        sendMessageOnRegistered(companyData);

        // upload image
        const url = await uploadLogo({
          logo: image,
          id: userCredential.user.uid,
        });

        await setDoc(doc(db, "companies", userCredential.user.uid), {
          ...fireStoreData,
          logo: url,
        });
        loadingRef.current.classList.remove("loading");
        navigate("/confirmation");
      })
      .catch((error) => {
        toast.error(error.message);
        loadingRef.current.classList.remove("loading");
      });
  };

  return (
    <>
      <form
        onSubmit={onSubmit}
        className="flex flex-col justify-center items-center w-full"
      >
        <div className="flex flex-col items-center justify-center my-20 md:items-start">
          <div className=" flex my-5 gap-5 mx-0">
            <h2 className="uppercase text-3xl md:text-4xl font-extrabold">
              Företagsinformation
            </h2>
          </div>

          <div className="flex flex-col md:flex-row my-5 gap-5 mx-0">
            <div>
              <input
                id="companyName"
                value={companyName}
                required
                onChange={onChange}
                type="text"
                placeholder="Företagsnamn"
                className="input input-md input-bordered w-96 max-w-xs bg-primary"
              />
            </div>
            <input
              type="text"
              id="vatNumber"
              value={vatNumber}
              onChange={onChange}
              required
              placeholder="Organisationsnummer"
              className="input input-md input-bordered  w-96 max-w-xs bg-primary"
            />
          </div>

          <div className="flex flex-col md:flex-row my-5 gap-5">
            <input
              required
              id="phoneNumber"
              value={phoneNumber}
              onChange={onChange}
              type="tel"
              placeholder="Telefonummer"
              className="input input-md input-bordered w-96 max-w-xs bg-primary"
            />
            <input
              type="text"
              id="contactPerson"
              value={contactPerson}
              onChange={onChange}
              placeholder="Kontaktperson"
              className="input input-md input-bordered w-96 max-w-xs bg-primary"
              required
            />
          </div>

          <div>
            <label className="label">
              <span className="label-text">Företagslogotyp</span>
            </label>
            <input
              id="logo"
              type="file"
              accept=".jpg,.png,.jpeg"
              onChange={(e) => {
                setImage(e.target.files);
              }}
              required
              className="file-input file-input-bordered file-input-md w-full max-w-xs"
            />
          </div>

          <div className=" flex my-5 gap-5 mx-0">
            <h2 className="w-[20rem] md:w-[41rem] shrink-0 uppercase">
              Företags Adress
            </h2>
          </div>

          <div className="flex flex-col md:flex-row my-5 gap-5">
            <input
              id="address"
              onChange={onChange}
              value={address}
              type="text"
              placeholder="Adress"
              className="input input-md input-bordered w-96 max-w-xs bg-primary"
              required
            />
            <input
              id="zipCode"
              value={zipCode}
              onChange={onChange}
              type="number"
              placeholder="Postnummer"
              required
              className="input input-md input-bordered w-96 max-w-xs bg-primary"
            />
          </div>

          <div className="flex flex-col md:flex-row my-5 gap-5">
            <TownSelect value={town} change={onChange} />

            <input
              id="websiteURL"
              value={websiteURL}
              onChange={onChange}
              type="url"
              placeholder="Hemsida"
              className="input input-md  input-bordered w-96 max-w-xs bg-primary"
            />
          </div>

          <div className=" flex my-5 gap-5 mx-0">
            <h2 className="w-[20rem] md:w-[41rem] shrink-0 uppercase">
            Inloggningsuppgifter
            </h2>
          </div>

          <div className="flex flex-col md:flex-row my-5 gap-5">
            <input
              id="email"
              value={email}
              onChange={onChange}
              type="email"
              required
              placeholder="E-post"
              className="input input-md input-bordered w-96 max-w-xs bg-primary"
            />
            <input
              id="password"
              value={password}
              onChange={onChange}
              required
              type="password"
              placeholder="Skriv in lösenord"
              className="input input-md input-bordered w-96 max-w-xs bg-primary"
              accept=".jpg,.png,.jpeg"
            />
          </div>

          <div className=" flex my-5 justify-center gap-5 mx-0">
            <button
              ref={loadingRef}
              type="submit"
              className="btn btn-md bg-accent text-white btn-wide "
            >
              Registrera
            </button>
          </div>
          <p className="mt-4 text-sm">
            Har du redan ett konto?{" "}
            <span
              className="underline cursor-pointer text-accent"
              onClick={() => {
                navigate("/");
              }}
            >
              {" "}
              logga in{" "}
            </span>
          </p>
        </div>
      </form>
    </>
  );
}

export default Register;
