import React from "react";

function test() {
  const bx = [
    { sub: "Hello there", body: "This is a body" },
    { sub: "Hello there", body: "This is a body" },
  ];

  return (
    <>
      {bx.map((test) => {
        return <h1>{test.sub}</h1>;
      })}
    </>
  );
}

export default test;
