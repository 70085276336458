import React from 'react'

function ContactPage() {
  return (
    <>
    
    <h2>HELLO COMTACT</h2>
    
    
    </>
  )
}

export default ContactPage